import { handleActions } from 'redux-actions';
import { UPDATE_CONTROLLER_STATE } from '../actions/controller-state';
import { ControllerState } from '../../worker/controller-handlers';

const initialState: ControllerState = {
  hasAccessToChannelByPricingPlan: false,
  isPricingPlanAccessInfoLoaded: false,
};

export default handleActions<ControllerState>(
  {
    [UPDATE_CONTROLLER_STATE]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
  initialState,
);
