import { createSelector } from 'reselect';
import {
  getChannelForWidget,
  isPricingPlanEnabled,
} from '@wix/wix-vod-shared/common';
import { controllerStateSelector } from './controlelr-state';

export const isAccessInfoLoadedSelector = createSelector(
  getChannelForWidget,
  controllerStateSelector,
  (channel, controllerState) =>
    isPricingPlanEnabled(channel)
      ? controllerState.isPricingPlanAccessInfoLoaded
      : true,
);
